<template>
  <div class="recruiting-big">
    <div class="top-search dfr flex-center">
      <van-search
        v-model="keyword"
        placeholder="请输入私董姓名"
        @blur="searchBlur"
        :left-icon="leftIcon"
      />
    </div>
    <!-- item -->
    <van-list
      v-model="loading"
      loading-text=""
      :offset="200"
      :finished="finished"
      v-if="siDongList.length"
      @load="onLoad"
    >
      <div class="dfc flex-center">
        <div
          class="recruiting-item"
          v-for="(item, index) in siDongList"
          :key="index"
        >
          <div class="item-box">
            <div class="dfr main-between flex1">
              <div class="dfr">
                <!-- 头像 -->
                <div class="item-portrait-box">
                  <!-- 用户头像 -->
                  <img
                    v-if="item.avatar"
                    :src="item.avatar"
                    alt=""
                    class="w100 h100 object-cover"
                  />
                  <!-- 默认头像 -->
                  <img
                    v-else
                    src="../assets/images/default-portrait.png"
                    alt=""
                    class="w100 h100"
                  />
                  <!-- 徽章 -->
                  <div v-if="item.is_major_auth" class="item-badge-box">
                    <img
                      src="../assets/images/home-emblem.png"
                      alt=""
                      class="w100 h100"
                    />
                  </div>
                </div>
                <!-- 信息 -->
                <div class="item-message flex1">
                  <div class="dfc flex1">
                    <div class="dfr cross-center">
                      <!-- 姓名 -->
                      <div class="fs32 fw600 fc333">{{ item.name }}</div>
                    </div>
                    <div class="item-com-job">
                      <!-- 职位 -->
                      <div class="fs24 fw400 fc999 item-job">
                        {{ item.job }}
                      </div>

                      <div
                        style="width: 0.02rem;height: 0.2rem;background: #CCCCCC;margin:0 0.12rem"
                        class="item-job"
                      />

                      <div class="fs24 fw400 fc999 item-company">
                        {{ item.company }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 添加img -->
              <div class="item-delete dfr cross-start" @click="addClick(item)">
                <img
                  :class="[item.canJoin ? '' : 'disabled']"
                  src="../assets/images/add.png"
                  alt=""
                  class="w100 h100"
                />
              </div>
            </div>
          </div>
          <!-- 描述 -->
          <div class="field-item-box dfr flex-wrap">
            <div v-for="(item_, index_) in item.field" :key="index_">
              <div class="field-item fs20 fw400 fc666">{{ item_.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <empty v-else></empty>
    <!-- 按钮 -->

    <div class="recruiting-button-wrap">
      <div class="dfr main-center cross-center recruiting-button">
        <div @click="returnS">
          <icy-button heigth="0.8rem" border-radius="0.44rem"> 返回</icy-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      siDongList: [],
      keyword: "",
      leftIcon: require("../assets/images/search.png"),
      id: "",
      loading: false,
      page: 1,
      pageSize: 6,
      finished: false,
      fieldId: "",
      joinIds: [],
      userId: ""
    };
  },
  async created() {
    this.id = parseInt(this.$route.query.id) || "";
    // this.fieldId = parseInt(this.$route.query.fieldId) || "";
    await this.getUserInfo();
    await this.getAlreadyJoin();
    await this.getSiDongList();
  },
  methods: {
    async onLoad() {
      if (!this.finished) {
        this.page++;
        const siDongList = await this.initSidongList();
        if (siDongList.length < this.pageSize) {
          this.siDongList = [...this.siDongList, ...siDongList];
          this.loading = false;
          this.finished = true;
        } else {
          this.siDongList = [...this.siDongList, ...siDongList];
          this.loading = false;
          this.finished = false;
        }
      }
    },
    async getUserInfo() {
      const resp = await this.$API.get("userInfo", false).then(resp => resp);
      if (resp.code == 1000) {
        this.userId = resp.data.info.id;
      }
    },
    async getAlreadyJoin() {
      const resp = await this.$API
        .get("joinMeetingSidongList", { id: this.id })
        .then(resp => resp);
      if (resp.code == 1000) {
        if (resp.data.list.length) {
          resp.data.list.forEach(item => {
            this.joinIds.push(item.user.id);
          });
        }
      }
    },
    async initSidongList() {
      let list = [];
      const data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword,
        fieldId: this.fieldId,
        orderType: 1,
        userId: this.userId
      };
      const resp = await this.$API
        .get("sidongList", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.total = resp.data.length;
        list = resp.data.list;
        list.forEach(item => {
          item.canJoin = true;
          this.joinIds.forEach(id => {
            if (id == item.id) {
              item.canJoin = false;
            }
          });
        });
      }
      return list;
    },
    // 获取
    async getSiDongList() {
      this.siDongList = await this.initSidongList();
      if (this.total < this.pageSize) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
    },
    searchBlur() {
      this.page = 1;
      this.getSiDongList();
    },
    returnS() {
      this.$back();
    },
    addClick(item) {
      if (item.canJoin) {
        this.addSiDong(item.id);
      }
    },
    // 添加接口
    async addSiDong(userid) {
      const resp = await this.$API
        .post("sidongAdd", { meetingId: this.id, userId: userid })
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("添加成功");
        this.siDongList.map(item => {
          if (item.id == userid) {
            item.canJoin = false;
          }
          return item;
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.item-delete {
  .disabled {
    filter: grayscale(100%);
  }
}
.recruiting-big {
  background: #f7f7f7;
  min-height: 100vh;
  padding-bottom: 0.2rem;
  .top-search {
    height: 0.96rem;
    width: 7.5rem;
    background: #ffffff;
    position: fixed;
    top: 0;
    z-index: 999;
    // border: 1px solid red;
  }
  .recruiting-item {
    width: 6.94rem;
    background: #ffffff;
    margin-top: 0.28rem;
    border-radius: 0.12rem;
    .item-box {
      margin: 0.36rem 0 0.36rem 0.36rem;
      .item-portrait-box {
        width: 0.96rem;
        height: 0.96rem;
        position: relative;
        img {
          border-radius: 50%;
          object-fit: cover;
        }
        .item-badge-box {
          width: 0.28rem;
          height: 0.28rem;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            position: absolute;
            object-fit: cover;
          }
        }
      }
      .item-message {
        margin-left: 0.2rem;
        .item-com-job {
          margin-top: 0.12rem;

          white-space: normal;
          word-break: break-all;
          .item-job {
            display: inline-block;
          }
          .item-company {
            display: inline;
          }
        }
      }
      .item-delete {
        max-width: 0.28rem;
        max-height: 0.28rem;
        margin-right: 0.36rem;
        object-fit: cover;
      }
    }
    .field-item-box {
      margin: 0.24rem 0.36rem 0.36rem 0.36rem;
      .field-item {
        background: #f6f6f6;
        padding: 0.08rem 0.16rem;
        margin: 0.12rem 0.12rem 0 0;
      }
    }
  }
  .recruiting-item:last-child {
    margin-bottom: 0.4rem;
  }
  .recruiting-item:first-child {
    margin-top: 1.22rem;
  }
  .recruiting-button-wrap {
    height: calc(1.2rem + env(safe-area-inset-bottom));
    height: calc(1.2rem + constant(safe-area-inset-bottom));
    background: #f7f7f7;
    .recruiting-button {
      position: fixed;
      bottom: 0;
      padding: 0.2rem 0.36rem;
      padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
      padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    }
  }
}
</style>
